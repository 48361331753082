import { Close } from "@storybook/assets";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface PopupComponentProps {
  closePopup: () => void;
  PopupComponent: () => JSX.Element;
}
interface PopupProps {
  header?: string;
  headerHidden?: boolean;
  size?: {
    minWidth?: string;
    width?: string;
    maxWidth?: string;
    minHeight?: string;
    height?: string;
    maxHeight?: string;
  };
  contentStyle?: React.CSSProperties;
  render?: JSX.Element;
  bodyScroll?: boolean;
}
const PopupComponent: PopupComponentProps = {
  closePopup: () => {},
  PopupComponent: () => {
    const [popup, setPopup] = useState<PopupProps | null>(null);

    // popup을 open하는 handler
    const handler = (props: CustomEvent<PopupProps>) => {
      const { detail } = props;
      setPopup(detail);
      if (detail.bodyScroll === false) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    };

    const closeHandler = () => setPopup(null);

    useEffect(() => {
      // 3번째 인자 false는 버블링, true는 캡쳐링
      window.addEventListener("Popup", handler as any, false);
      window.addEventListener("PopupClose", closeHandler, false);

      PopupComponent.closePopup = () => setPopup(null);

      return () => {
        window.removeEventListener("Popup", handler as any, false);
        window.addEventListener("PopupClose", closeHandler, false);
      };
    }, []);

    if (popup) {
      return (
        <S.Popup>
          <S.PopupCard
            style={{
              width: popup.size?.width,
              height: popup.size?.height,
              minWidth: popup.size?.minWidth,
              minHeight: popup.size?.minHeight,
              maxWidth: popup.size?.maxWidth,
              maxHeight: popup.size?.maxHeight,
            }}
          >
            {!popup.headerHidden && (
              <S.PopupHeader>
                <S.PopupHeaderText>{popup?.header ?? ""}</S.PopupHeaderText>
                {/* <S.Close onClick={() => setPopup(null)} /> */}
                <S.Close onClick={closeHandler} />
              </S.PopupHeader>
            )}

            {popup.render &&
              React.cloneElement(
                popup.render,
                {},
                <S.PopupContent style={popup.contentStyle}>{popup.render.props.children}</S.PopupContent>,
              )}
          </S.PopupCard>
        </S.Popup>
      );
    }
    return <></>;
  },
};

const Popup = (detail: PopupProps) => {
  return window.dispatchEvent(
    new CustomEvent("Popup", {
      detail,
    }),
  );
};
export { Popup, PopupComponent };

const S = {
  Popup: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2121211a;
    z-index: 29;
  `,
  PopupCard: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 2px 4px 20px 10px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    z-index: 29;
    min-width: 480px;
    min-height: 200px;
    overflow: visible;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 35px;
  `,

  PopupHeader: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  PopupHeaderText: styled.div`
    flex: 1;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #444444;
  `,
  PopupContent: styled.div`
    display: flex;
    /* flex-direction: column; */
    flex: 1;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 20px;
  `,
  Close: styled(Close)`
    cursor: pointer;
  `,
};
